// Update the count down every 1 second
const countdown = function (date) {
  // Set the date we're counting down to
  const countDownDate = new Date(date + " GMT-7").getTime();

  return setInterval(function () {
    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (days > 0) {
      document.getElementsByClassName("d-tag")[0].classList.remove("d-none");
      document.getElementById("d-tag").innerHTML = days;
    }

    document.getElementById("h-tag").innerHTML = hours;
    document.getElementById("m-tag").innerHTML = minutes;
    document.getElementById("s-tag").innerHTML = seconds;

    // If the count down is over, write some text
    if (distance < 0) {
      clearInterval(x);
      //document.getElementById("countdown").innerHTML = "EXPIRED";
    } else {
      document.getElementById("countdown").classList.remove("d-none");
    }
  }, 1000);
};

export default countdown;
