// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
import { post } from "jquery";
import Popper from "popper.js";
import countdown from "./countdown";
window.jQuery = $;
window.$ = $;

require("bootstrap");
require("bootstrap-select");

$(function () {
  $("#registrationForm").on("submit", function (e) {
    e.preventDefault();
    var formURL = "https://b7ptkjl4u4.execute-api.us-west-2.amazonaws.com/dev/subscribe";
    var redirectURL = "livestream.html";

    const form = document.querySelector("form");

    // Capture the form data
    let data = {};
    Array.from(form).map(input => (data[input.name] = input.value));
    console.log("Sending: ", JSON.stringify(data));

    // Create the AJAX request
    var xhr = new XMLHttpRequest();
    xhr.open("POST", formURL, true);
    xhr.setRequestHeader("Accept", "application/json; charset=utf-8");
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");

    // Send the collected data as JSON
    xhr.send(JSON.stringify(data));

    xhr.onloadend = response => {
      if (response.target.status === 200) {
        window.location.href = redirectURL;
      } else {
        console.log("Error! Please try again.");
        console.error(JSON.parse(response));
      }
    };
  });

  if (document.getElementById("countdown")) {
    // Set here the target datetime
    countdown("03/18/2021 09:00:00");
  }
});
